import {config} from '../config';

export function getLocationPrefix(): string {
    const {protocol, host} = document.location;
    return `${protocol}//${host}`;
}

const baseUrl = getLocationPrefix();

export const customCustomerSubDomain: string | undefined =
    baseUrl === config.profileUrl || baseUrl === config.dashboardUrl
        ? undefined
        : document.location.host.replace(/^([^.]+)\..+$/, '$1');

export function isDashboardUrl(url: string): boolean {
    if (url.startsWith(config.dashboardUrl)) {
        return true;
    }

    if (customCustomerSubDomain) {
        if (url.startsWith(getLocationPrefix())) {
            const u = new URL(url);
            if (!u.pathname.startsWith('/p/')) {
                return true;
            }
        }
    }

    return false;
}

export function isProfileUrl(url: string) {
    if (url.startsWith(config.profileUrl)) {
        return true;
    }

    if (customCustomerSubDomain) {
        if (url.startsWith(getLocationPrefix())) {
            const u = new URL(url);
            if (u.pathname.startsWith('/p/')) {
                return true;
            }
        }
    }

    return false;
}
