import {ComponentType, lazy, LazyExoticComponent} from 'react';
import {getLocalStorage} from './storage';

export function lazyWithRetry<T extends ComponentType<any>>(
    key: string,
    factory: () => Promise<{default: T}>,
): LazyExoticComponent<T> {
    const storageKey = `phbfr-${key}`;

    const ls = getLocalStorage();

    if (!ls) {
        return lazy<T>(factory);
    }

    return lazy<T>(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            ls.getItem(storageKey) || 'false',
        );

        try {
            const component = await factory();

            ls.setItem(storageKey, 'false');

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                ls.setItem(storageKey, 'true');
                window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
}
